<template>
	<div >

		<feature-screen image="/features/spoferan-meine-sporteventcommunity.jpg" lang-prefix="view.index">

			<template #cta>
				<btn @click="scrollToContent" shadow x-large :label="$t('action.learn_more')" :icon="icons.down"/>
				<btn :to="$spoferanUrl('register')" shadow x-large color="primary"  :label="`${t('action.get_started_now_for_free')}!`"/>
			</template>

			<template #footer>
				<div class="grid grid-cols-2 gap-x-5 border-t pt-8 mt-8 dark:border-neutral-700">
					<app-feature-rating-button type="apple"/>
					<app-feature-rating-button type="google"/>
				</div>
			</template>

		</feature-screen>

		<container class="divide-y dark:divide-neutral-700">

			<feature-section label="content" lang-prefix="view.feature_types" full-width>
				<template #text>
					<div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
						<pre-card :to="$web('spoferan.index')"
								  toptitle="SPOFERAN"
								  :title="$t('view.card_athletes.title')"
								  :description="$t('view.card_athletes.description')"
								  :button="{label: $t('action.learn_more')}"
								  :image="{src: '/views/about/spoferan-for-athletes.png', size: {width: 180, ratio: 2, sm: {width: 380}}}"/>

						<pre-card :to="$web('resultastic.index')"
								  toptitle="resultastic"
								  :title="$t('view.card_organizers.title')"
								  :description="$t('view.card_organizers.description')"
								  :button="{label: $t('action.learn_more')}"
								  :image="{src: '/views/about/spoferan-for-organizers.png', size: {width: 180, ratio: 2, sm: {width: 380}}}"/>

						<pre-card :to="$web('clubmanager.index')"
								  toptitle="SPOFERAN Clubmanager"
								  :title="$t('view.card_clubs.title')"
								  :description="$t('view.card_clubs.description')"
								  :button="{label: $t('action.learn_more')}"
								  :image="{src: '/views/about/spoferan-for-clubs.png', size: {width: 180, ratio: 2, sm: {width: 380}}}"/>

						<pre-card :to="$web('businessmanager.index')"
								  toptitle="SPOFERAN Businessmanager"
								  :title="$t('view.card_companies.title')"
								  :description="$t('view.card_companies.description')"
								  :button="{label: $t('action.learn_more')}"
								  :image="{src: '/views/about/spoferan-for-companies.png', size: {width: 180, ratio: 2, sm: {width: 380}}}"/>

					</div>

				</template>
			</feature-section>

			<feature-section lang-prefix="view.feature_athletes" right cols="7">

				<template #cta>
					<btn :to="$spoferanUrl('dashboard')" :label="`${$t('action.get_started_now')}!`" color="primary" shadow x-large/>
					<btn :to="$web('spoferan.index')" :label="$t('action.learn_more')" :icon="icons.next" icon-right shadow x-large/>
				</template>

				<nuxt-link :to="$web('spoferan.index')">
					<cdn-image :src="Images.spoferan.logo.path" alt="SPOFERAN"
							   :size="{width: 320, ratio: Images.spoferan.logo.ratio, sm: {width: 420}}"/>
				</nuxt-link>

			</feature-section>

			<feature-section lang-prefix="view.feature_organizers" cols="7">

				<template #cta>
					<btn :to="$config.public.resultasticUrl" :label="`${$t('action.get_started_now')}!`" color="primary" shadow x-large/>
					<btn :to="$web('resultastic.index')" :label="$t('action.learn_more')" :icon="icons.next" icon-right shadow x-large/>
				</template>

				<nuxt-link :to="$web('resultastic.index')">
					<cdn-image :src="Images.resultastic.logo.path" alt="resultastic"
							   class="flex dark:hidden"
							   :size="{width: 320, ratio: Images.resultastic.logo.ratio, sm: {width: 420}}"/>
					<cdn-image :src="Images.resultastic.logo_white.path" alt="resultastic"
							   class="hidden dark:flex"
							   :size="{width: 320, ratio: Images.resultastic.logo_white.ratio, sm: {width: 420}}"/>
				</nuxt-link>
			</feature-section>

			<feature-section lang-prefix="view.feature_clubs" cols="7" right>

				<template #cta>
					<btn :to="$spoferanUrl('clubs/create')" :label="`${$t('action.get_started_now')}!`" color="primary" shadow x-large/>
					<btn :to="$web('clubmanager.index')" :label="$t('action.learn_more')" :icon="icons.next" icon-right shadow x-large/>
				</template>

				<nuxt-link :to="$web('clubmanager.index')">
					<cdn-image :src="Images.clubmanager.logo.path" alt="SPOFERAN Clubmanager"
							   :size="{width: 320, ratio: Images.clubmanager.logo.ratio, sm: {width: 420}}"/>
				</nuxt-link>
			</feature-section>

			<feature-section lang-prefix="view.feature_companies" cols="7">

				<template #cta>
					<btn :to="$spoferanUrl('companies/create')" :label="`${$t('action.get_started_now')}!`" color="primary" shadow x-large/>
					<btn :label="$t('action.learn_more')" :icon="icons.next" icon-right shadow x-large/>
				</template>

				<nuxt-link :to="$web('businessmanager.index')">
					<cdn-image :src="Images.businessmanager.logo.path" alt="SPOFERAN Businessmanager"
							   :size="{width: 320, ratio: Images.businessmanager.logo.ratio, sm: {width: 420}}"/>
				</nuxt-link>
			</feature-section>

			<feature-section lang-prefix="view.feature_app" cols="7" right>

				<template #cta>
					<btn :to="$web('spoferan.app')" :label="$t('action.learn_more')" :icon="icons.next" icon-right shadow x-large/>
				</template>

				<div class="flex flex-col items-center justify-end">
					<div class="grid grid-cols-2 gap-x-5 pb-8 mb-6 border-b dark:border-b-neutral-700">
						<app-feature-rating-button type="apple"/>
						<app-feature-rating-button type="google"/>
					</div>
					<partner-connection-buttons/>
				</div>
			</feature-section>
		</container>
	</div>
</template>

<script setup lang="ts">

import {Images} from "@spoferan/spoferan-ts-core";
import {mdiChevronDown} from "@mdi/js";
import {useInfoPage} from "~/composables/useInfoPage";
import {nextIcon} from "@spoferan/nuxt-spoferan/icons";

const {$image} = useNuxtApp();
const {t} = useI18n()

const icons = {
	next: nextIcon,
	down: mdiChevronDown,
};

const {scrollToContent} = useInfoPage();

useMeta({
	title: t('view.index.title'),
	description: t('view.index.description'),
	image: $image.format('/features/spoferan-meine-sporteventcommunity.jpg', {width: 1200, height: 1200})
})

</script>